import { Button, Heading, Img, Text } from "components";
import CryptoCapLandingPageFeature from "components/CryptoCapLandingPageFeature";
import React from "react";

export default function Features() {
  return (
    <div className="h-full w-full relative mt-[100px]">
      {/* <Img
        src="images/img_bg_features.svg"
        alt="bgfeatures_one"
        className="justify-center h-fullopacity-0.15"
      /> */}
      <div className="flex flex-col items-center justify-center w-full h-full gap-[76px]">
        <div className="flex flex-col items-center justify-start w-[50%] sm:w-full gap-[27px]">
          <div className="flex flex-row justify-center w-full max-w-[502px] ">
            <Heading size="xl" as="h2" className="text-center">
              Our B2B Services
            </Heading>
          </div>
          <div className="flex flex-row justify-center w-full">
            <Text size="md" as="p" className="text-center">
              Accelerate your project's growth with our specialized and
              impactful services.
            </Text>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full gap-[127px]">
          <div className="flex flex-row sm:flex-col w-full items-center justify-center gap-6">
            <CryptoCapLandingPageFeature className="flex flex-col items-start justify-center gap-[38px] p-7 border-white-A700_0c border border-solid bg-white-A700_05 rounded-[18px]" />
            <CryptoCapLandingPageFeature
              manage="images/img_feature_image_1_1.png"
              manage1="Project Launch Article"
              buyandsell="Launch your new Crypto project with impact through our expertly crafted article service."
              className="flex flex-col items-start justify-center gap-[38px] p-7 border-white-A700_0c border border-solid bg-white-A700_05 rounded-[18px]"
            />
            <CryptoCapLandingPageFeature
              manage="images/img_feature_image_1_80x80.png"
              manage1="Detailed Article"
              buyandsell="Unleash the full potential of your crypto project with our detailed article service."
              className="flex flex-col items-start justify-center gap-[38px] p-7 border-white-A700_0c border border-solid bg-white-A700_05 rounded-[18px]"
            />
            <CryptoCapLandingPageFeature
              manage="images/img_feature_image_1_2.png"
              manage1="Advertise With Us"
              buyandsell="Amplify brand visibility with our targeted advertisement solution from Banners to pop-up ads."
              className="flex flex-col items-start justify-center gap-[38px] p-7 border-white-A700_0c border border-solid bg-white-A700_05 rounded-[18px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
